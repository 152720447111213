<template>
  <div id="container">
    <v-form ref="form" v-model="valid" lazy-validation>
      <div>
      <v-text-field
        v-model="amount"
        placeholder="Enter Amount you wish to Donate"
        outlined
        label="Amount (USD)"
        class="rounded-lg"
        :rules="[v => !!v || 'Amount is required']"
        :error-messages="error"
      >
      </v-text-field>
    </div>
    <div ref="paypal" ></div>
    </v-form>
    <v-alert
      type="success"
      dismissible
      v-if="paymentStatus"
    >
      <v-icon color="success">mdi-check-circle</v-icon>
      <span class=""> Thank You for your Donation</span>
    </v-alert>
    <div class="text-right mt-n5">
      <v-btn text :color="primaryBlue" @click="$emit('close')"> Cancel</v-btn>
    </div>
  </div>
</template>

<script>
import colorMxn from '@/mixins/color_mixin';

export default {
  name: 'paypal',
  mixins: [colorMxn],
  data() {
    return {
      loaded: false,
      amount: '',
      error: '',
      valid: '',
      paymentStatus: false,
    }
  },
  mounted() {
    console.log('setload called');
    const script = document.createElement("script");
    const clientId = process.env.VUE_APP_PAYPAL_CLIENT_ID;
    script.src =
      `https://www.paypal.com/sdk/js?client-id=${clientId}`;
    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);
  },
  methods: {
    setLoaded() {
      this.loaded = true;
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: 'Description for Paypal',
                  amount: {
                    currency_code: "USD",
                    value: this.amount,
                  }
                }
              ]
            });
          },
          onApprove: async (data, actions) => {
            console.log(actions);
            const order = await actions.order.capture();
            console.log(order);
            if (order.status === 'COMPLETED') {
              this.paymentStatus = true;
            }
          },
          onError: err => {
            console.log(err);
          },
          onClick: (data, actions) => {
            if (!this.$refs.form.validate()) {
              this.error = "Amount is required before donation";
              return actions.reject();
            } 
            
            return actions.resolve();
          }
        })
        .render(this.$refs.paypal);
    }
  }

}
</script>
