<template>
  <v-card :color="theme ? '' : grey1" flat id="donate">
    <v-container>
      <v-row>
        <v-col cols=8>
          <v-card flat color="transparent">
            <v-card-title>
              <span class="font-weight-bold">Help us by Donating!</span>
            </v-card-title>
            <v-card-text class="">
              Up until now, this whole project is funded by us, meaning that every donation can help 
              us evolve and expand in many directions. If you are able to donate any amount, feel free to do so!
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <div class="mt-10">
            <v-btn v-if="!showPaypal" class="pa-5 mt-5" color="black" dark @click="showPaypal=!showPaypal"> Donate Now </v-btn>
            <paypalBtn @close="showPaypal = false" v-else />
          </div>
        </v-col>
      </v-row>
    </v-container>


  </v-card>
</template>

<script>
import colorMxn from '@/mixins/color_mixin';
// import PayPal from 'vue-paypal-checkout'
import paypalBtn from './paypalBtn';


export default {
  name: 'Donate',
  mixins: [colorMxn],
  components: {
    paypalBtn,
  },
  data() {
    return {
      amount: '',
      showPaypal: false,
    }
  },
  methods: {

  }
}
</script>
